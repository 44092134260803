@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
body {
  min-height: 100vh;
  min-height: stretch;
  min-height: -webkit-fill-available;
}
html {
  height: stretch;
  height: -webkit-fill-available;
}
body {
  margin: 0;
  font-family: 'Varela Round', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  overflow: hidden;
  background-image: url('./assets/WaveBLUE.jpg');
  background-position: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.unselectable {
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}