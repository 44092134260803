.folderName {
    text-align: center;
    margin: 0;
    padding: 0;
}

.folderContainer {
    margin: 10px;
}

.folderIconContainer {
    text-align: center;
    /* margin-bottom: -10px; */
}